import {
  ChakraProvider,
  theme,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Image,
  Box,
  VStack,
  Flex,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { QuestionIcon, AddIcon } from '@chakra-ui/icons';
import { SiHulu, SiNetflix, SiPrimevideo } from 'react-icons/fa';
//import Button from './components/button';
//import Banner from './';
//import Card from './';
import Header from './header';
import Info from './info';
import Accolade from './accolade';
import EpisodeGuide from './episodes';

const CardBody = () => {
  return (
    <Container maxW="100%" pt="2" bg="#140908" color="white">
      <Container bg="#140908">
        <Text
          noOfLines="1"
          align="center"
          fontSize="3xl"
          fontWeight="800"
          textShadow={'-1px 1px #fce0de'}
          m="5"
        >
          Chef Sandy Squillante
        </Text>
      </Container>
      {/* info cards */}
      <VStack spacing={[8, null, 24]}>
        <Info
          num={'1'}
          side={'row-reverse'}
          pic={'SandyWithText.png'}
        />
        <Info num={'2'} side={'row'} pic={'01.jpeg'} />
        <Info num={'3'} side={'row'} pic={'02.jpeg'} />
        <Info num={'4'} side={'row'} pic={'03.jpeg'} />
        <Info num={'1'} side={'row'} pic={'04.jpeg'} />
      </VStack>
      <Box flexDirection={'column'} w="100%" py="4">
        <Text fontSize="2xl" fontWeight={700} textAlign="center">
          Watch us on your favorite streaming network!
        </Text>
        <Flex
          //flexDirection={'row'}
          h="auto"
          w="100%"
          justifyContent="center"
          alignContent="center"
          //flexWrap={['wrap', 'nowrap']}
          py="8"
          gap="10px"
        >
          <Accolade />
          {/* <Accolade />
          <Accolade />
          <Accolade />
          <Accolade /> */}
        </Flex>
      </Box>
      {/* Header 2 */}
      <Container maxW="container.xl" bg="#140908" py="2">
        <Box py="4">
          {/* <Text fontSize="l" fontWeight="700" textAlign="center">
            Because it is agile.
          </Text> */}
        </Box>
      </Container>
    </Container>
  );
};

export default CardBody;

/* <Image
        src="https://resizer.otstatic.com/v2/photos/wide-huge/1/28759583.jpg"
        position="fixed"
        opacity={'.3'}
        width="100%"
        height="100%"
      /> */
