import {
  Flex,
  Box,
  Image,
  Text,
  Container,
  AspectRatio,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
//import '../public/images';

const Info = (props) => {
  const [toggle, setToggle] = useState(props.side);
  const handleToggle = () => {
    toggle === '' ? setToggle('') : setToggle(props.side);
  };

  return (
    <Container maxW="100vw" fontSize="xl">
      <Flex
        flexDirection={['column', null, 'row']}
        display="flex"
        justifyContent="center"
      >
        <Image
          fallbackSrc="01.jpeg"
          src={`${props.pic}`}
          fit="cover"
          width={['100%', null, '75%']}
          h={['100%', null, '75%']}
          pb={[3, null, 0]}
          // pl={[0, null, props.side === 'row-reverse' ? 5 : 0]}
          // pr={[0, null, props.side === 'row' ? 5 : 0]}
        />
      </Flex>
      {props.pic === '01.jpeg' && (
        <Box color="white" p="2">
          <Text
            style={{
              textIndent: '1rem',
            }}
            py=".5em"
          >
            Romancing The Pan is a branded entertainment cooking
            series that explores new trends and twists in American
            recipes that have established America as a melting pot of
            cuisines from around the world.
          </Text>
          <Text py="1em">
            Chef Sandy demonstrates that preparing a meal is more than
            simply baking, blending, or sautéing. It's about
            re-discovering old family traditions, building new ones,
            and loving your family and friends with delicious food and
            entertainment which is why she always has her co-host and
            music man by her side. Cooking is always more fun when you
            get to have fun alongside and experience it with others.
          </Text>
          <Text py="1em">
            Sandy strives to share her passion and help rejuvenate
            viewers souls each week on Romancing The Pan series with
            live music that gets her and the people moving, lively
            banter, and a myriad of cuisines. Having fun and loving
            the art is what being in the kitchen is to her. It’s all
            about the experience. "Become a part of my family at the
            first bite". -Sandy
          </Text>
        </Box>
      )}
    </Container>
  );
};
export default Info;
/* <Flex flexDirection="row">
        <Box w="20%" paddingRight="4" flex="1 1">
          <Text
            bg="#140908"
            color="#8dd9cd"
            fontSize="5xl"
            fontWeight={700}
          >
            {props.num}
          </Text>
        </Box>

        <Box>
          <Text fontSize="xl" fontWeight="700">
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Text>
          <Text fontSize="md" fontWeight="400">
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua.
          </Text>
        </Box>
      </Flex> */
