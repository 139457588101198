import React from 'react';
import {
  Box,
  Container,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
} from '@chakra-ui/react';

const EpisodeGuide = () => {
  return (
    <Container
      maxW={['80vw', null, '50vw']}
      fontSize="2xl"
      border="1px solid #140908 "
    >
      <Accordion border="1px solid #140908">
        <AccordionItem>
          <h2>
            <AccordionButton
              _expanded={{ bg: '#140908', color: 'white' }}
              _hover={{ color: 'white', bg: '#140908' }}
            >
              <Image src="01.jpeg" w="20%" />
              <Spacer />
              <Box
                flex="1"
                textAlign="left"
                fontWeight={700}
                fontSize={('3xl', null, 'xl')}
              >
                Episode 1
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton
              _expanded={{ bg: '#140908', color: 'white' }}
              _hover={{ color: 'white', bg: '#140908' }}
            >
              <Image src="03.jpeg" maxW="20%" /> <Spacer />
              <Box
                flex="1"
                textAlign="left"
                fontWeight={700}
                fontSize={('3xl', null, 'xl')}
              >
                Episode 2
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );
};
export default EpisodeGuide;
