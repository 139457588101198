import React from 'react';
import ReactPlayer from 'react-player';
import { Container, Image, Box } from '@chakra-ui/react';
import Video from '../images/Promo 2022.mp4';
const Hero = () => {
  return (
    <Box w="100%" p="0">
      <video
        controls
        autoPlay=""
        muted=""
        playsInline=""
        width="100%"
        height="100%"
        preload="auto"
        src={Video}
        type="video/mp4"
      ></video>
    </Box>
  );
};

export default Hero;
