import {
  ChakraProvider,
  Collapse,
  extendTheme,
  useDisclosure,
  Box,
  Container,
} from '@chakra-ui/react';

import NavBar from './components/header';
import Footer from './components/footer';
import Card from './components/card';
import Hero from './components/hero';
import { Global } from '@emotion/react';
import EpisodeGuide from './components/episodes';

function App() {
  const theme = extendTheme({
    fonts: {
      body: `${'Edu TAS Beginner, cursive'}`,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <NavBar />
      <Hero />
      <Card />
      <EpisodeGuide />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
