import {
  Box,
  Flex,
  ButtonGroup,
  Container,
  IconButton,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';
import { withTheme } from '@emotion/react';
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaTwitter,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <Flex
        py="2"
        flexDirection={['column', null, 'column']}
        flexWrap="wrap"
        textAlign="center"
      >
        <Box
          as="button"
          fontSize="2xl"
          fontWeight="500"
          borderBottom="2px"
          borderTop="2px"
          py="2"
          px="4"
          _hover={{ color: 'white', bg: '#140908' }}
        >
          Recipes
        </Box>
        <Box
          as="button"
          fontSize="2xl"
          fontWeight="500"
          borderBottom="2px"
          py="2"
          _hover={{ color: 'white', bg: '#140908' }}
        >
          Career
        </Box>
      </Flex>
      <Container
        as="footer"
        role="contentinfo"
        py={{ base: '8', md: '12' }}
      >
        <Stack spacing={{ base: '4', md: '5' }}>
          <Stack
            justify="space-evenly"
            direction="row"
            align="center"
          >
            <ButtonGroup variant="ghost">
              <IconButton
                as="a"
                href="#"
                aria-label="FaceBook"
                icon={<FaFacebookSquare fontSize="3rem" />}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="GitHub"
                icon={<FaInstagramSquare fontSize="3rem" />}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="Twitter"
                icon={<FaTwitter fontSize="3rem" />}
              />
            </ButtonGroup>
          </Stack>
          <Flex justifyContent="center">
            <Text fontSize="lg" color="subtle">
              &copy; {new Date().getFullYear()} Romancing The Pan,
              Inc. All rights reserved.
            </Text>
          </Flex>
        </Stack>
      </Container>
    </>
  );
};
export default Footer;
