import {
  Box,
  Flex,
  Image,
  Icon,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/react';
import { SiHulu, SiNetflix, SiPrimevideo } from 'react-icons/si';
const Accolade = (props) => {
  return (
    <Flex
      p="0"
      w="100%"
      h="auto"
      justifyContent="center"
      alignSelf="center"
      spacing="1"
    >
      <ButtonGroup variant="solid" color="white" spacing="10">
        <IconButton
          as="a"
          href="#"
          aria-label="A&E"
          icon={<Icon as={Image} src="a_e.png" size="100%" />}
          size="lg"
          _hover={{ color: 'teal', bg: '#teal' }}
        />
        <IconButton
          as="a"
          href="#"
          aria-label="Hulu"
          icon={<SiHulu color="black" size="100%" />}
          size="lg"
          _hover={{ color: 'white', bg: 'teal' }}
        />

        <IconButton
          as="a"
          href="#"
          aria-label="{props.label}"
          icon={<SiNetflix color="black" size="100%" />}
          size="lg"
          _hover={{ color: 'white', bg: 'teal' }}
        />
        <IconButton
          as="a"
          href="#"
          aria-label="{props.label}"
          icon={<Icon as={Image} src="disc+.png" size="100w" />}
          size="lg"
          _hover={{ color: 'white', bg: 'teal' }}
        />
        <IconButton
          as="a"
          href="#"
          aria-label="{props.label}"
          icon={<SiPrimevideo size="100%" color="black" />}
          size="lg"
          _hover={{ color: 'white', bg: 'teal' }}
        />
      </ButtonGroup>
    </Flex>
  );
};

export default Accolade;

//   <Image
//     fallbackSrc="https://via.placeholder.com/100x55"
//     src="../src/images/01.jpeg"
//     fit="cover"
//     width="100%"
//     h="auto"
//     border="none"
//   />
